/* eslint-disable import/no-cycle */
import React, { useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import { useWebSocket } from "contexts/websockets";
import { PENDING_REQUESTS } from "./graphql";
import {
  fetchPendingFailure,
  fetchPendingInit,
  fetchPendingSuccess,
} from "./pending.actions";
import {
  getDefaultPendingState,
  PendingReducer,
  PendingState,
} from "./pending.reducer";
import { PendingActionType } from "./pending.types";

interface PendingStateDispatch {
  state: PendingState;
  dispatch: React.Dispatch<PendingActionType>;
}

const PendingContext = React.createContext<PendingStateDispatch | null>(null);

type PendingProviderProps = {
  children?: React.ReactNode;
  [key: string]: any;
};

export const PendingProvider = (props: PendingProviderProps) => {
  const [loadPendingRequests] = useLazyQuery(PENDING_REQUESTS);

  const [state, dispatch] = React.useReducer(
    PendingReducer,
    getDefaultPendingState()
  );

  const value = React.useMemo(() => ({ state, dispatch }), [state]);

  const ws = useWebSocket();

  const loadPendingRequestsByMyFamily = async () => {
    try {
      const {
        data: { pendingRequests },
      } = await loadPendingRequests();

      fetchPendingSuccess(dispatch)(pendingRequests);
    } catch (ex) {
      console.log(JSON.stringify(ex));
      fetchPendingFailure(dispatch)();
    }
  };

  useEffect(() => {
    (ws as any).socket.on("event::refreshPendingRequest", () => {
      fetchPendingInit(dispatch)();
      loadPendingRequestsByMyFamily();
    });
    fetchPendingInit(dispatch)();
    loadPendingRequestsByMyFamily();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <PendingContext.Provider value={value} {...props} />;
};

export const usePending = () => {
  const context = React.useContext(PendingContext);

  if (!context) {
    throw new Error("usePending must be used within a PendingProvider!");
  }

  return context.state;
};
