export enum UserRole {
  SUPER_ADMIN = "SUPER_ADMIN",
  ADMIN = "ADMIN",
  USER = "USER",
}
export enum UserStatus {
  ALL = "ALL",
  PENDING = "PENDING",
  VERIFIED = "VERIFIED",
  ACTIVE = "ACTIVE",
  INACTIVATED = "INACTIVATED",
}

export enum Gender {
  MALE = "MALE",
  FEMALE = "FEMALE",
  UNKNOWN = "UNKNOWN",
}

export const UserRoles = {
  [UserRole.SUPER_ADMIN]: "System Administrator",
  [UserRole.ADMIN]: "Administrator",
  [UserRole.USER]: "User",
};

export const UserGenders = {
  [Gender.MALE]: "Male",
  [Gender.FEMALE]: "Female",
  [Gender.UNKNOWN]: "Unknown",
};

export const UserStatuses = {
  [UserStatus.ALL]: "All",
  [UserStatus.ACTIVE]: "Active Users",
  [UserStatus.PENDING]: "Pending Users",
};
