import { gql } from "@apollo/client";
export const pending_request_fields = gql`
  fragment PendingRequestFields on PendingRequest {
    relationship
    createdAt
    sender {
      id
      userLogin
      person {
        id
        firstName
        lastName
        birthday
        gender
      }
    }
    receiver {
      id
      userLogin
      person {
        id
        firstName
        lastName
        birthday
        gender
      }
    }
  }
`;

export const PENDING_REQUESTS = gql`
  ${pending_request_fields}
  query pendingRequests {
    pendingRequests {
      family {
        ...PendingRequestFields
      }
      friend {
        ...PendingRequestFields
      }
    }
  }
`;
