import React, { createContext } from "react";
import { io } from "socket.io-client";
import { WS_BASE } from "config";

const WebSocketContext = createContext(null);

export const useWebSocket = () => {
  const context = React.useContext(WebSocketContext);
  if (!context) {
    throw new Error("useWebSocket must be used within a WebSocketProvider!");
  }

  return context;
};

const WebSocketProvider = ({ children }) => {
  let socket;
  let ws;

  const sendUserId = (userId: string) => {
    console.log("sendUserId", userId);
    socket.emit("event::uid", userId);
  };
  const sendPendingRefreshRequest = (userId: string) => {
    socket.emit("event::refreshPendingRequest", userId);
  };

  const sendMessage = (event: string, payload: any) => {
    if (typeof payload === "string") {
      socket.emit(event, payload);
    } else if (typeof payload === "undefined") {
      socket.emit(event, "");
    } else {
      socket.emit(event, JSON.stringify(payload));
    }
  };

  if (!socket) {
    socket = io(WS_BASE || "");
    ws = {
      socket,
      sendMessage,
      sendUserId,
      sendPendingRefreshRequest,
    };
  }

  return (
    <WebSocketContext.Provider value={ws}>{children}</WebSocketContext.Provider>
  );
};
export default WebSocketProvider;
