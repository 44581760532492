import { gql } from '@apollo/client';

export const AUTHENTICATE_MUTATION = gql`
  mutation authenticate($userLogin: String!) {
    authenticate(userLogin: $userLogin) {
      accessToken
      refreshToken
    }
  }
`;

export const ME = gql`
  query me{
    me {
      id
      userLogin
      role    
      organizations {
        id
        organizationName
        address {
          address1
          address2
          address3
          city
          state
          type
          householdName
          moveinDate
        }
      }
      status
      person {
        firstName
        lastName
        title
        notes
        phone
        profilePicture
        gender
        birthday
        addresses {
          address1
          address2
          address3
          city
          state
          type
          householdName
          moveinDate
        }
        socialLinks {
          type
          link
        }
      }
    }
  }
`