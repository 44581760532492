import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { useDispatch } from "react-redux";
import { useMutation, useLazyQuery } from "@apollo/client";
import { AuthUser } from "../../../../types/models/AuthUser";
import {
  fetchError,
  fetchStart,
  fetchSuccess,
} from "../../../../redux/actions";
import { AUTHENTICATE_MUTATION, ME } from "./graphql";
import { User } from "types/models/User";
import { useWebSocket } from "contexts/websockets";

interface JWTAuthContextProps {
  user: AuthUser | null | undefined;
  isAuthenticated: boolean;
  isLoading: boolean;
}

interface SignInProps {
  email: string;
}

interface JWTAuthActionsProps {
  signInUser: (data: SignInProps) => void;
  setUser: (user: User) => void;
  logout: () => void;
}

const JWTAuthContext = createContext<JWTAuthContextProps>({
  user: null,
  isAuthenticated: false,
  isLoading: true,
});
const JWTAuthActionsContext = createContext<JWTAuthActionsProps>({
  signInUser: () => {},
  setUser: () => {},
  logout: () => {},
});

export const useJWTAuth = () => useContext(JWTAuthContext);

export const useJWTAuthActions = () => useContext(JWTAuthActionsContext);

interface JWTAuthProviderProps {
  children: ReactNode;
}

const JWTAuthProvider: React.FC<JWTAuthProviderProps> = ({ children }) => {
  const [authData, setJWTAuthData] = useState<JWTAuthContextProps>({
    user: null,
    isAuthenticated: false,
    isLoading: true,
  });
  const ws = useWebSocket();
  const [authenticate] = useMutation(AUTHENTICATE_MUTATION);
  const [loadMe] = useLazyQuery(ME, { variables: {} });
  const dispatch = useDispatch();

  const getAuthUser = async () => {
    const token = localStorage.getItem("token");

    if (!token) {
      setJWTAuthData({
        user: undefined,
        isLoading: false,
        isAuthenticated: false,
      });
      return;
    }

    try {
      const {
        data: { me },
      } = await loadMe();
      setJWTAuthData({
        user: me,
        isAuthenticated: true,
        isLoading: false,
      });
      (ws as any).sendUserId(me.id);
    } catch (err) {
      setJWTAuthData({
        user: undefined,
        isLoading: false,
        isAuthenticated: false,
      });
    }
  };

  useEffect(() => {
    getAuthUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const signInUser = async ({ email }: { email: string }) => {
    dispatch(fetchStart());
    try {
      const {
        data: {
          authenticate: { accessToken },
        },
      } = await authenticate({ variables: { userLogin: email } });
      localStorage.setItem("token", accessToken);

      const {
        data: { me },
      } = await loadMe();
      setJWTAuthData({
        user: me,
        isAuthenticated: true,
        isLoading: false,
      });
      (ws as any).sendUserId(me.id);
      dispatch(fetchSuccess());
    } catch (error) {
      setJWTAuthData({
        ...authData,
        isAuthenticated: false,
        isLoading: false,
      });
      dispatch(fetchError((error as any).graphQLErrors[0].message));
    }
  };

  const logout = async () => {
    localStorage.removeItem("token");
    setJWTAuthData({
      user: null,
      isLoading: false,
      isAuthenticated: false,
    });
  };

  const setUser = async (user) => {
    setJWTAuthData({
      user,
      isLoading: false,
      isAuthenticated: true,
    });
  };

  return (
    <JWTAuthContext.Provider
      value={{
        ...authData,
      }}
    >
      <JWTAuthActionsContext.Provider
        value={{
          signInUser,
          logout,
          setUser,
        }}
      >
        {children}
      </JWTAuthActionsContext.Provider>
    </JWTAuthContext.Provider>
  );
};
export default JWTAuthProvider;
