import { SystemPendingRequests } from "types/models/SystemPendingRequests";

export enum PendingActionId {
  FETCH_PENDING_INIT,
  FETCH_PENDING_FAILURE,
  FETCH_PENDING_SUCCESS,
}

type FetchPendingInitAction = {
  type: PendingActionId.FETCH_PENDING_INIT;
};

type FetchPendingFailureAction = {
  type: PendingActionId.FETCH_PENDING_FAILURE;
};

type FetchPendingSuccessAction = {
  type: PendingActionId.FETCH_PENDING_SUCCESS;
  payload: { pending: SystemPendingRequests };
};

export type PendingActionType =
  | FetchPendingInitAction
  | FetchPendingFailureAction
  | FetchPendingSuccessAction;
