import React from "react";
import { Provider } from "react-redux";
import CssBaseline from "@mui/material/CssBaseline";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";

import AuthRoutes from "@crema/utility/AuthRoutes";
import AppContextProvider from "@crema/utility/AppContextProvider";
import AppThemeProvider from "@crema/utility/AppThemeProvider";
import AppStyleProvider from "@crema/utility/AppStyleProvider";
import AppLocaleProvider from "@crema/utility/AppLocaleProvider";
import AppLayout from "@crema/core/AppLayout";
import configureStore from "redux/store";
import { BrowserRouter } from "react-router-dom";
import JWTAuthProvider from "@crema/services/auth/jwt-auth/JWTAuthProvider";
import { GRAPHQL_URL } from "config";
import WebSocketProvider from "contexts/websockets";
import { PendingProvider } from "contexts/pending";

const store = configureStore();

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem("token");
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});
const httpLink = createHttpLink({
  uri: GRAPHQL_URL,
});
const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

const App = () => (
  <ApolloProvider client={client}>
    <WebSocketProvider>
      <AppContextProvider>
        <Provider store={store}>
          <PendingProvider>
            <AppThemeProvider>
              <AppStyleProvider>
                <AppLocaleProvider>
                  <BrowserRouter>
                    <JWTAuthProvider>
                      <AuthRoutes>
                        <CssBaseline />
                        <AppLayout />
                      </AuthRoutes>
                    </JWTAuthProvider>
                  </BrowserRouter>
                </AppLocaleProvider>
              </AppStyleProvider>
            </AppThemeProvider>
          </PendingProvider>
        </Provider>
      </AppContextProvider>
    </WebSocketProvider>
  </ApolloProvider>
);

export default App;
