import React from "react";

const Organizations = React.lazy(() => import("./Organizations"));
const Users = React.lazy(() => import("./Users"));

export const managePagesConfigs = [
  {
    path: "/manage/organizations",
    element: <Organizations />,
  }, {
    path: "/manage/users",
    element: <Users />,
  },
];
