import moment from "moment";
import { authRole } from "../../shared/constants/AppConst";
import { Breakpoint } from "@mui/system";
import { AddressNode } from "types/models/AddressNode";
import { Person } from "types/models/Person";
import { UserFormValues } from "types/models/UserFormValues";
import { UserInput } from "types/models/User";
import { SocialNode } from "types/models/SocialNode";
import { UserRelationship } from "types/models/UserRelationship";
import { FamilyTreeNode } from "types/models/FamilyTreeNode";
import { Gender } from "constants/user";

export const getBreakPointsValue = (valueSet: any, breakpoint: Breakpoint) => {
  if (typeof valueSet === "number") return valueSet;
  switch (breakpoint) {
    case "xs":
      return valueSet.xs;
    case "sm":
      return valueSet.sm || valueSet.xs;
    case "md":
      return valueSet.md || valueSet.sm || valueSet.xs;
    case "lg":
      return valueSet.lg || valueSet.md || valueSet.sm || valueSet.xs;
    default:
      return (
        valueSet.xl || valueSet.lg || valueSet.md || valueSet.sm || valueSet.xs
      );
  }
};

export const getFileSize = (bytes: number) => {
  if (bytes === 0) return "0 Bytes";
  let k = 1024,
    dm = 2,
    sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
    i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

export const multiPropsFilter = (
  products: any[],
  filters: any,
  stringKey = "title"
) => {
  const filterKeys = Object.keys(filters);
  return products.filter((product) => {
    return filterKeys.every((key) => {
      if (!filters[key].length) return true;
      // Loops again if product[key] is an array (for material attribute).
      if (Array.isArray(product[key])) {
        return product[key].some((keyEle: any) =>
          filters[key].includes(keyEle)
        );
      }
      console.log("key", key, filters[key], product[key]);
      if (key === stringKey) {
        return product[key].toLowerCase().includes(filters[key].toLowerCase());
      }
      return filters[key].includes(product[key]);
    });
  });
};

export const getCustomDateTime = (
  value = 0,
  unit = "days",
  format = "YYYY-MM-DD"
) => {
  if (value === 0) {
    return moment().format(format);
  } else {
    // @ts-ignore
    return moment().add(value, unit).format(format);
  }
};

export const capitalizeText = (str: string) => {
  str = str.toLowerCase();
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const timeFromNow = (date: any) => {
  const timestamp = moment(date).format("X");
  const newDate = moment.unix(Number(timestamp));
  return moment(newDate).fromNow();
};

export const checkPermission = (routeAuth: any, userRole: any) => {
  if (routeAuth === null || routeAuth === undefined) {
    return true;
  }

  if (userRole && Array.isArray(userRole)) {
    return routeAuth.some((r: any) => userRole.indexOf(r) >= 0);
  }

  if (routeAuth.length === 0) {
    return !userRole || userRole.length === 0;
  }
  if (userRole && Array.isArray(userRole) && Array.isArray(routeAuth)) {
    return routeAuth.some((r) => userRole.indexOf(r) >= 0);
  }
  return routeAuth.indexOf(userRole) >= 0;
};

export const generateUniqueID = () => {
  return `v1-${Date.now()}-${Math.floor(Math.random() * (9e12 - 1)) + 1e12}`;
};

export const getUserFromAuth0 = (user: any) => {
  if (user)
    return {
      id: 1,
      uid: user.sub,
      displayName: user.name,
      email: user.email,
      photoURL: user.picture,
      role: authRole.user,
    };
  return user;
};

export const getUserFromFirebase = (user: any) => {
  console.log("user: ", user);
  if (user)
    return {
      id: 1,
      uid: user.uid,
      displayName: user.displayName ? user.displayName : "Crema User",
      email: user.email,
      photoURL: user.photoURL,
      role: authRole.user,
    };
  return user;
};
export const getUserFromAWS = (user: any) => {
  if (user)
    return {
      id: 1,
      uid: user.username,
      displayName: user.attributes.name ? user.attributes.name : "Crema User",
      email: user.attributes.email,
      photoURL: user.photoURL,
      role: authRole.user,
    };
  return user;
};

export const getUserFromJwtAuth = (user: any) => {
  if (user)
    return {
      id: 1,
      uid: user._id,
      displayName: user.name,
      email: user.email,
      photoURL: user.avatar,
      role: authRole.user,
    };
  return user;
};

export const formattedAddress = (address: AddressNode | null) => {
  if (!address) {
    return "";
  }

  const addrLists: string[] = [];
  if (address.address1) {
    addrLists.push(address.address1);
  }
  if (address.address2) {
    addrLists.push(address.address2);
  }
  if (address.address3) {
    addrLists.push(address.address3);
  }
  if (address.city) {
    addrLists.push(address.city);
  }
  if (address.state) {
    if (address.zip) {
      addrLists.push(`${address.state} ${address.zip}`);
    } else {
      addrLists.push(`${address.state}`);
    }
  }
  return addrLists.join(", ");
};

export const listToTree = (list: any) => {
  const map = {},
    roots: any[] = [];

  list.forEach((item: any, index: number) => {
    map[item.id] = index;
    list[index].children = [];
  });

  list.forEach((item: any) => {
    if (item.parentId !== "") {
      list[map[item.parentId]].children.push(item);
    } else {
      roots.push(item);
    }
  });
  return roots;
};

export const getInitialOfPerson = (p: Person | undefined) => {
  if (!p) {
    return "";
  }

  return `${p.firstName.charAt(0)}${p.lastName.charAt(0)}`;
};

export const getFullnameOfPerson = (p: Person | undefined) => {
  if (!p) {
    return "";
  }

  return `${p.firstName} ${p.lastName}`;
};

export const convertFormToUser = (values: UserFormValues): UserInput => {
  const user: UserInput = {
    id: values.id,
    userLogin: values.userLogin,
    role: values.role,
    organizations: [values.orgId],
    person: {
      id: values.personId,
      firstName: values.firstName,
      lastName: values.lastName,
      profilePicture: values.profilePicture,
      title: values.title,
      notes: values.notes,
      gender: values.gender,
      birthday: values.birthday,
      phone: values.phone,
      addresses: values.addresses.map((addr: AddressNode) => ({
        id: addr.id,
        address1: addr.address1,
        address2: addr.address2,
        address3: addr.address3,
        city: addr.city,
        state: addr.state,
        zip: addr.zip,
        type: addr.type,
        householdName: addr.householdName,
        moveinDate: addr.moveinDate,
      })),
      socialLinks: values.socialLinks.map((social: SocialNode) => ({
        type: social.type,
        link: social.link,
      })),
    },
  } as UserInput;
  return user;
};

export const stringToColor = (str: string): string => {
  let hash = 0;
  let i: number = 0;

  /* eslint-disable no-bitwise */
  for (i = 0; i < str.length; i += 1) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    const strValue = `00${value.toString(16)}`;
    color += strValue.substring(strValue.length - 2);
  }
  return color;
};

export const getBirthday = (birthday: string | undefined): string => {
  if (!birthday) {
    return "";
  }
  return moment(birthday).format("MMM Do, YYYY");
};

export const getFamilyTreeNodes = (
  primaryFamily: UserRelationship[],
  secondaryFamily: UserRelationship[]
): FamilyTreeNode[] => {
  const nodes: FamilyTreeNode[] = [];
  if (secondaryFamily.length > 0) {
    const owner = secondaryFamily.find(
      (r: UserRelationship) => r.relationship === "OWNER"
    );
    const husband = secondaryFamily.find(
      (r: UserRelationship) =>
        r.relationship === "HUSBAND" || r.relationship === "HUSBAND-PENDING"
    );
    const wife = secondaryFamily.find(
      (r: UserRelationship) =>
        r.relationship === "WIFE" || r.relationship === "WIFE-PENDING"
    );
    const childs = secondaryFamily.filter(
      (r: UserRelationship) =>
        r.relationship === "CHILD" || r.relationship === "CHILD-PENDING"
    );
    let currentLen = nodes.length;
    let motherId = 0,
      fatherId = 0,
      ownerId = 0;
    if (owner) {
      nodes.push({
        uid: owner.user.id,
        id: currentLen + 1,
        name: `${owner.user.person.firstName} ${owner.user.person.lastName}`,
        gender: owner.user.person.gender || Gender.MALE,
        img: owner.user.person.profilePicture || "/assets/images/man.png",
        email: owner.user.userLogin,
        title: owner.user.person.title,
        birthday: getBirthday(owner.user.person.birthday),
        phone: owner.user.person.phone,
      });
      ownerId = currentLen + 1;
      fatherId = ownerId;
    }

    if (husband) {
      currentLen = nodes.length;
      nodes.push({
        uid: husband.user.id,
        id: currentLen + 1,
        name: `${husband.user.person.firstName} ${husband.user.person.lastName}`,
        gender: husband.user.person.gender || Gender.MALE,
        img: husband.user.person.profilePicture || "/assets/images/man.png",
        pids: [ownerId],
        email: husband.user.userLogin,
        title: husband.user.person.title,
        birthday: getBirthday(husband.user.person.birthday),
        phone: husband.user.person.phone,
        tags: husband.relationship === "HUSBAND-PENDING" ? ["Pending"] : [],
      });
      nodes[currentLen - 1].pids = [currentLen + 1];
      nodes[currentLen - 1].gender = Gender.FEMALE;
      nodes[currentLen - 1].img =
        nodes[currentLen - 1].img || "/assets/images/woman.png";
      motherId = ownerId;
      fatherId = currentLen + 1;
    }
    if (wife) {
      currentLen = nodes.length;
      nodes.push({
        uid: wife.user.id,
        id: currentLen + 1,
        name: `${wife.user.person.firstName} ${wife.user.person.lastName}`,
        gender: Gender.FEMALE,
        img: wife.user.person.profilePicture || "/assets/images/woman.png",
        pids: [ownerId],
        email: wife.user.userLogin,
        title: wife.user.person.title,
        birthday: getBirthday(wife.user.person.birthday),
        phone: wife.user.person.phone,
        tags: wife.relationship === "WIFE-PENDING" ? ["Pending"] : [],
      });
      nodes[currentLen - 1].pids = [currentLen + 1];
      nodes[currentLen - 1].img =
        nodes[currentLen - 1].img || "/assets/images/man.png";
      nodes[currentLen - 1].gender = Gender.MALE;

      motherId = currentLen + 1;
      fatherId = ownerId;
    }
    childs.forEach((ch: UserRelationship) => {
      currentLen = nodes.length;
      nodes.push({
        uid: ch.user.id,
        id: currentLen + 1,
        name: `${ch.user.person.firstName} ${ch.user.person.lastName}`,
        gender: Gender.FEMALE,
        img: ch.user.person.profilePicture || "/assets/images/man.png",
        ...(motherId > 0 ? { mid: motherId } : {}),
        ...(fatherId > 0 ? { fid: fatherId } : {}),
        email: ch.user.userLogin,
        title: ch.user.person.title,
        birthday: getBirthday(ch.user.person.birthday),
        phone: ch.user.person.phone,
        tags: ch.relationship === "CHILD-PENDING" ? ["Pending"] : [],
      });
    });
  }

  if (primaryFamily.length > 0) {
    const owner = primaryFamily.find(
      (r: UserRelationship) => r.relationship === "OWNER"
    );
    const myId =
      secondaryFamily.findIndex(
        (r: UserRelationship) => r.user.id === owner?.user.id
      ) + 1;
    const husband = primaryFamily.find(
      (r: UserRelationship) =>
        r.relationship === "HUSBAND" || r.relationship === "HUSBAND-PENDING"
    );
    const wife = primaryFamily.find(
      (r: UserRelationship) =>
        r.relationship === "WIFE" || r.relationship === "WIFE-PENDING"
    );
    const childs = primaryFamily.filter(
      (r: UserRelationship) =>
        r.relationship === "CHILD" || r.relationship === "CHILD-PENDING"
    );
    let currentLen = nodes.length;
    let motherId = 0,
      fatherId = 0,
      ownerId = 0;
    let mm: FamilyTreeNode;
    if (owner && myId === 0) {
      mm = {
        uid: owner.user.id,
        id: currentLen + 1,
        name: `${owner.user.person.firstName} ${owner.user.person.lastName}`,
        gender: owner.user.person.gender || Gender.MALE,
        img: owner.user.person.profilePicture || "/assets/images/man.png",
        email: owner.user.userLogin,
        title: owner.user.person.title,
        birthday: getBirthday(owner.user.person.birthday),
        phone: owner.user.person.phone,
        pending: false,
        tags: ["Me"],
      };
      nodes.push(mm);
      ownerId = currentLen + 1;
      fatherId = ownerId;
    } else {
      mm = nodes.find(
        (n) => n.email === owner?.user.userLogin
      ) as FamilyTreeNode;
      if (mm) {
        mm.tags = ["Me"];
        ownerId = mm.id;
        fatherId = ownerId;
      }
    }

    if (husband) {
      currentLen = nodes.length;
      nodes.push({
        uid: husband.user.id,
        id: currentLen + 1,
        name: `${husband.user.person.firstName} ${husband.user.person.lastName}`,
        gender: husband.user.person.gender || Gender.MALE,
        img: husband.user.person.profilePicture || "/assets/images/man.png",
        pids: [ownerId],
        email: husband.user.userLogin,
        title: husband.user.person.title,
        birthday: getBirthday(husband.user.person.birthday),
        phone: husband.user.person.phone,
        tags: husband.relationship === "HUSBAND-PENDING" ? ["Pending"] : [],
      });
      mm.pids = [currentLen + 1];
      mm.gender = Gender.FEMALE;
      mm.img = mm.img || "/assets/images/woman.png";
      motherId = ownerId;
      fatherId = currentLen + 1;
    }
    if (wife) {
      currentLen = nodes.length;
      nodes.push({
        uid: wife.user.id,
        id: currentLen + 1,
        name: `${wife.user.person.firstName} ${wife.user.person.lastName}`,
        gender: Gender.FEMALE,
        img: wife.user.person.profilePicture || "/assets/images/woman.png",
        pids: [ownerId],
        email: wife.user.userLogin,
        title: wife.user.person.title,
        birthday: getBirthday(wife.user.person.birthday),
        phone: wife.user.person.phone,
        tags: wife.relationship === "WIFE-PENDING" ? ["Pending"] : [],
      });
      mm.pids = [currentLen + 1];
      mm.img = mm.img || "/assets/images/man.png";
      mm.gender = Gender.MALE;

      motherId = currentLen + 1;
      fatherId = ownerId;
    }
    childs.forEach((ch: UserRelationship) => {
      currentLen = nodes.length;
      nodes.push({
        uid: ch.user.id,
        id: currentLen + 1,
        name: `${ch.user.person.firstName} ${ch.user.person.lastName}`,
        gender: ch.user.person.gender || Gender.MALE,
        img: ch.user.person.profilePicture || "/assets/images/man.png",
        ...(motherId > 0 ? { mid: motherId } : {}),
        ...(fatherId > 0 ? { fid: fatherId } : {}),
        email: ch.user.userLogin,
        title: ch.user.person.title,
        birthday: getBirthday(ch.user.person.birthday),
        phone: ch.user.person.phone,
        tags: ch.relationship === "CHILD-PENDING" ? ["Pending"] : [],
      });
    });
  }

  return nodes;
};
