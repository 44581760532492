/* eslint-disable import/no-cycle */
import { SystemPendingRequests } from "types/models/SystemPendingRequests";
import { PendingActionId, PendingActionType } from "./pending.types";

type DispatchType = React.Dispatch<PendingActionType>;

export const fetchPendingInit = (dispatch: DispatchType) => () =>
  dispatch({ type: PendingActionId.FETCH_PENDING_INIT });

export const fetchPendingFailure = (dispatch: DispatchType) => () =>
  dispatch({ type: PendingActionId.FETCH_PENDING_FAILURE });

export const fetchPendingSuccess =
  (dispatch: DispatchType) => (pending: SystemPendingRequests) =>
    dispatch({
      type: PendingActionId.FETCH_PENDING_SUCCESS,
      payload: { pending },
    });
