import { PendingRequest } from "types/models/PendingRequest";
import { SystemPendingRequests } from "types/models/SystemPendingRequests";
import { PendingActionType, PendingActionId } from "./pending.types";

export type PendingState = {
  pending: SystemPendingRequests;
  isLoading: boolean;
  isError: boolean;
};

export function getDefaultPendingState(): PendingState {
  return {
    pending: {
      family: [] as PendingRequest[],
      friend: [] as PendingRequest[],
    },
    isLoading: false,
    isError: false,
  };
}

export function PendingReducer(
  state: PendingState,
  action: PendingActionType
): PendingState {
  switch (action.type) {
    case PendingActionId.FETCH_PENDING_INIT: {
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    }
    case PendingActionId.FETCH_PENDING_FAILURE: {
      return {
        ...state,
        isLoading: false,
        isError: true,
      };
    }
    case PendingActionId.FETCH_PENDING_SUCCESS: {
      const { pending } = action.payload;
      return {
        ...state,
        isLoading: false,
        pending,
        isError: false,
      };
    }

    default: {
      throw new Error("Unsupported action type");
    }
  }
}
