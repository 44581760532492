import React, { ReactNode } from "react";
import { BiVector, BiUser } from "react-icons/bi";
import { RoutePermittedRole } from "../shared/constants/AppConst";

export interface RouterConfigData {
  id: string;
  title: string;
  messageId: string;
  icon?: string | ReactNode;
  type: "item" | "group" | "collapse" | "divider";
  children?: RouterConfigData[];
  permittedRole?: RoutePermittedRole;
  color?: string;
  url?: string;
  exact?: boolean;
  count?: number;
}

const routesConfig: RouterConfigData[] = [
  {
    id: "management",
    title: "Management",
    messageId: "sidebar.manage",
    type: "group",
    children: [
      {
        id: "organizations",
        title: "Organizations",
        messageId: "sidebar.manage.organizations",
        type: "item",
        icon: <BiVector />,
        url: "/manage/organizations",
      },
      {
        id: "users",
        title: "Users",
        messageId: "sidebar.manage.users",
        type: "item",
        icon: <BiUser />,
        url: "/manage/users",
      },
    ],
  },
];
export default routesConfig;
